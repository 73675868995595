<template>
  <v-card class="mx-auto" :disabled="loading">
    <v-card-title>
      <v-row dense>
        <v-col cols="12" xs="12" md="8">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                small
                plain
                icon
                :to="{
                  name:
                    type_bill == 1 ? 'facturacion' : 'facturacion_servicios',
                }"
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
            </template>
            <span v-text="'Átras'" />
          </v-tooltip>
          <span
            class="text-h6 ml-1"
            v-text="
              $route.meta.title + (loading ? '' : ' (' + tbl_data.length + ')')
            "
          />
        </v-col>
        <v-col cols="12" xs="12" md="4" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="info"
                @click.stop="faqs_dlg = true"
                :loading="faqs_ldg"
                :disabled="faqs.length == 0"
              >
                <v-icon> mdi-help </v-icon>
              </v-btn>
            </template>
            <span v-text="'Ayuda'" />
          </v-tooltip>
        </v-col>
        <v-col cols="12" xs="12" md="4">
          <v-select
            label="Estado"
            v-model="tbl_filter"
            :items="tbl_filters"
            item-value="id"
            :item-text="(v) => v.value"
            dense
            :disabled="loading"
            @change="getTableData"
          />
        </v-col>
        <v-col v-if="tbl_data.length > 0" cols="12" xs="12" md="12">
          <v-text-field
            v-model="tbl_search"
            append-icon="mdi-magnify"
            label="Buscar..."
            single-line
            hide-details
            dense
          />
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col cols="12">
          <v-data-table
            :headers="tbl_headers"
            :search="tbl_search"
            :items="tbl_data"
            :loading="loading"
            :items-per-page="15"
            dense
          >
            <template v-slot:item.key="{ item }">
              <b v-text="item.key" />
            </template>
            <template v-slot:item.action="{ item }">
              <div class="text-right">
                <v-tooltip left>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      fab
                      dark
                      x-small
                      v-on="on"
                      :color="
                        (item.response ? 'green' : 'orange') + ' darken-1'
                      "
                      @click="billCommentDlg(item)"
                      class="ml-1"
                    >
                      <v-icon>
                        mdi-comment-{{
                          item.discharged
                            ? "check"
                            : !item.response
                            ? "arrow-right"
                            : "arrow-left"
                        }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span v-text="'Detalle'" />
                </v-tooltip>
                <!-- <v-tooltip v-if="item.response && !item.discharged" left>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      fab
                      dark
                      x-small
                      v-on="on"
                      color="warning"
                      @click="billCommentDischarged(item)"
                      class="ml-1"
                    >
                      <v-icon> mdi-comment-check </v-icon>
                    </v-btn>
                  </template>
                  <span v-text="'Terminar'" />
                </v-tooltip> -->
              </div>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>

    <v-dialog v-model="bill_comment_dlg" scrollable persistent max-width="800">
      <v-card
        tile
        :disabled="bill_comment_dlg_ldg"
        :loading="bill_comment_dlg_ldg"
      >
        <v-toolbar dark dense>
          <v-toolbar-title> {{ folio }} | COMENTARIOS </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="bill_comment_dlg = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="bill_comment_dlg">
          <v-row class="pt-4">
            <v-col v-if="bill_comment" cols="12">
              <v-timeline align-top dense>
                <v-timeline-item
                  v-for="(item, i) in bill_comment.bill_comment_details"
                  :key="i"
                  :color="(item.response ? 'green' : 'orange') + ' darken-1'"
                  :icon="
                    'mdi-' +
                    (bill_comment &&
                    bill_comment.discharged &&
                    bill_comment.bill_comment_details.length - 1 == i
                      ? 'check'
                      : 'arrow-' + (item.response ? 'left' : 'right'))
                  "
                  small
                >
                  <div>
                    <div class="font-weight-light">
                      {{ item.created_at }} | {{ item.user_email }}
                    </div>
                    <div
                      v-if="item.bill_comment_type_name"
                      class="font-weight-bold"
                      v-text="item.bill_comment_type_name"
                    />
                    <div v-text="item.detail" />
                  </div>
                </v-timeline-item>
              </v-timeline>
            </v-col>
            <v-col v-if="bill_comment_detail" cols="12">
              <v-form
                v-on:submit.prevent
                ref="bill_comment_detail_form"
                lazy-validation
              >
                <v-row dense class="px-5 mx-5">
                  <v-col cols="12">
                    <v-textarea
                      label="Detalle"
                      v-model="bill_comment_detail.detail"
                      rows="2"
                      dense
                      :rules="rules.required"
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-btn
                      block
                      dark
                      color="green darken-1"
                      @click.prevent="billCommentSubmit"
                    >
                      Responder
                      <v-icon right small> mdi-comment-arrow-left </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <FaqDlg v-model="faqs_dlg" :faqs="faqs" />
  </v-card>
</template>

<script>
import Axios from "axios";
import {
  URL_API,
  headersToken,
  rules,
  msgAlert,
  msgConfirm,
  URL_DOCUMENTS,
} from "../../control";
import ViewData from "../../components/ViewData.vue";
import FaqDlg from "../../components/FaqDlg.vue";

export default {
  components: {
    ViewData,
    FaqDlg,
  },
  data() {
    return {
      url_documents: URL_DOCUMENTS,
      login: this.$store.getters.getLogin,
      rules: rules(),
      type_bill: null,
      loading: true,
      tbl_data: [],
      tbl_search: "",
      tbl_headers: [
        { text: "#", filterable: false, value: "key", width: "80" },
        { text: "ID", filterable: true, value: "id", width: "80" },
        { text: "Folio", filterable: true, value: "folio", width: "120" },
        { text: "Usuario", filterable: true, value: "created_by.email" },
        { text: "Creado", filterable: true, value: "created_at" },
        { text: "Terminado", filterable: true, value: "discharged" },
        {
          text: "",
          fixed: true,
          value: "action",
          sortable: false,
          width: "110",
        },
      ],
      tbl_filter: 0,
      tbl_filters: [
        { id: 0, value: "PENDIENTES" },
        { id: 1, value: "TERMINADOS" },
        { id: 2, value: "TODOS" },
      ],
      folio: null,
      bill_comment: null,
      bill_comment_dlg: false,
      bill_comment_dlg_ldg: false,
      bill_comment_detail: null,
      doctor_comment_detail: null,
      faqs: [],
      faqs_dlg: false,
      faqs_ldg: true,
    };
  },
  methods: {
    getTableData() {
      this.loading = true;
      this.tbl_data = [];

      Axios.get(
        URL_API +
          "/bill_comments?filter=" +
          this.tbl_filter +
          "&type_bill=" +
          this.type_bill,
        headersToken(this.login.token)
      ).then((res) => {
        this.tbl_data = res.data.data;
        this.loading = false;
      });
    },
    billCommentDlg(item) {
      this.key = item.key;
      this.folio = item.folio;
      this.bill_comment_detail = null;

      this.bill_comment = null;
      this.bill_comment_dlg_ldg = true;
      this.bill_comment_dlg = true;

      Axios.get(
        URL_API + "/bill_comments/" + item.id,
        headersToken(this.login.token)
      ).then((res) => {
        this.bill_comment = res.data.data;

        if (
          !this.bill_comment.bill_comment_details[
            this.bill_comment.bill_comment_details.length - 1
          ].response
        ) {
          this.bill_comment_detail = {
            id: null,
            detail: "",
            response: true,
            bill_comment_type_id: null,
            bill_comment_id: item.id,
          };
        }
        this.bill_comment_dlg_ldg = false;
      });
    },
    billCommentSubmit() {
      if (this.$refs.bill_comment_detail_form.validate()) {
        this.$swal
          .fire(msgConfirm("¿Confirma enviar el comentario?"))
          .then((res) => {
            if (res.isConfirmed) {
              this.bill_comment_dlg_ldg = true;

              Axios.post(
                URL_API + "/bill_comment_details",
                this.bill_comment_detail,
                headersToken(this.login.token)
              ).then((res) => {
                this.$swal.fire(
                  msgAlert(
                    res.data.success ? "success" : "error",
                    res.data.message
                  )
                );

                if (res.data.success) {
                  // this.tbl_data[this.key - 1].response = true;
                  this.getTableData();
                  this.bill_comment_dlg = false;
                } else {
                  console.log(res.data.message);
                }

                this.bill_comment_dlg_ldg = false;
              });
            }
          });
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido, revisa los detalles señalados"
          )
        );
      }
    },
    billCommentDischarged(item) {
      this.$swal
        .fire(msgConfirm("¿Confirma terminar la conversación?"))
        .then((res) => {
          if (res.isConfirmed) {
            this.key = item.key;
            this.loading = true;

            Axios.post(
              URL_API + "/bill_comments/discharged",
              {
                id: item.id,
              },
              headersToken(this.login.token)
            ).then((res) => {
              this.$swal.fire(
                msgAlert(
                  res.data.success ? "success" : "error",
                  res.data.message
                )
              );

              if (res.data.success) {
                this.tbl_data[this.key - 1].discharged = res.data.discharged;
                if (this.tbl_filter == 0) {
                  this.tbl_data.splice(this.key - 1, 1);
                }
              } else {
                console.log(res.data.message);
              }

              this.loading = false;
            });
          }
        });
    },
  },
  mounted() {
    this.route_name = this.$route.name;
    this.route_name = this.route_name.split(".");
    this.route_name = this.route_name[0];
    this.type_bill = this.route_name == "rha_bills" ? 1 : 2;

    Axios.get(
      URL_API + "/faqs/faq_interfaces/" + this.$route.meta.title,
      headersToken(this.login.token)
    ).then((resp) => {
      this.faqs = resp.data.data;
      this.faqs_ldg = false;
    });

    this.getTableData();
  },
};
</script>